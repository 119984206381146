/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	font-size: 20px;

	--preColor-light: #eee;
	--preColor-light-2: #fff;
	--preColor-dark: #222;
	--preColor-dark-2: #333;
	--preColor-primary: #0070f3;
	--preColor-primary-2: #4d9fff;
	--preColor-secondary: #00f285;
	--preColor-secondary-2: #56ffb0;

	--color-foundation: var(--preColor-light);
	--color-foundationAlt: var(--preColor-light-2);
	--color-base: var(--preColor-dark);
	--color-primary: var(--preColor-primary-2);
	--color-primary-2: var(--preColor-primary);
	--color-secondary: var(--preColor-secondary-2);
	--color-secondary-2: var(--preColor-secondary);
}

@media (prefers-color-scheme: dark) {
	:root {
		--color-foundation: var(--preColor-dark);
		--color-foundationAlt: var(--preColor-dark-2);
		--color-base: var(--preColor-light);
		--color-primary: var(--preColor-primary-2);
		--color-primary-2: var(--preColor-primary);
		--color-secondary: var(--preColor-secondary);
		--color-secondary-2: var(--preColor-secondary-2);
	}
}

body {
	background: var(--color-foundation);
	color: var(--color-base);
}

body,
html {
	margin: 0;
	width: 100%;
	height: 100%;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p,
h1,
h2,
h3,
h4,
h5 {
	margin: 0.5rem 0;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
